<template>
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <!-- <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Country Subscribers</div>
            <div class="subtitle-1 font-weight-light">
              A list of Country Subscribers
            </div>
          </template>
          <v-card-text>
            <v-row
              class="mx-0"
              align="center"
              justify="center"
              justify-md="end"
            >
              <v-col cols="12" md="8" lg="5">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="px-0">
                <v-data-table
                  :headers="headers"
                  :search="search"
                  :items="items"
                  :loading="isLoading"
                >
                  <template v-slot:[`item.email`]="{ item }">
                    <v-tooltip bottom v-if="item.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ item.name }}</b> via
                        <b>
                          <i>{{ item.email }}</i>
                        </b>
                      </span>
                    </v-tooltip> </template
                  >>
                  <template v-slot:[`item.approval`]="{ item }">
                    <ApprovalConfirmation
                      :item="item"
                      :key="`itemId-approval ${item._id}`"
                      :approvalFn="approvalFn"
                    />
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <DeleteConfirmation
                      :key="item._id"
                      :item="payloadGen(item)"
                      itemtype="Country Subscriber"
                      @setDelete="deleteFn"
                    />
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-btn
                      icon
                      color="grey lighten-1"
                      @click="RouteFn(item._id, item.email)"
                    >
                      <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card> -->
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <v-tabs
              v-model="CountrySubTabs"
              background-color="transparent"
              slider-color="white"
            >
              <span
                class="display-2 font-weight-light mx-3"
                style="align-self: center"
              >Country Subscribers:</span
              >
              <v-tab class="mr-3">
                <font-awesome-icon :icon="['fas', 'user']" class="mr-2"/>
                Active Subscribers
              </v-tab>
              <v-tab class="mr-3">
                <font-awesome-icon
                  :icon="['fas', 'user-alt-slash']"
                  class="mr-2"
                />
                Inactive Subscribers
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="CountrySubTabs" class="transparent">
            <!-- Active subscribers -->
            <v-tab-item>
              <v-card-text>
                <v-row
                  class="mx-0 mb-0"
                  align="center"
                  justify="center"
                  justify-md="start"
                >
                  <v-col cols="12" md="8" lg="5">
                    <v-text-field
                      v-model="activeSearch"
                      append-icon="mdi-magnify"
                      label="Search by country or country rep"
                      single-line
                      outlined
                      hide-details
                      @keyup.native="searchFn"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" lg="1">
                    <v-btn
                      color="primary"
                      @click="clearFilterFn"
                      :variant="activeSearch ? 'ash-gray' : 'primary'"
                      :disabled="!activeSearch"
                    >
                      Reset
                    </v-btn>
                  </v-col>
                </v-row
                >
                <v-data-table
                  :headers="activeHeaders"
                  :items="activeItems"
                  :loading="isLoading"
                  :items-per-page="10"
                  :options.sync="activePagination"
                  :footer-props="footerProps"
                  @update:items-per-page="getActiveItemPerPage"
                  :server-items-length="totalOutputsActive"
                >
                  <template v-slot:[`item.email`]="{ item }">
                    <v-tooltip bottom v-if="item.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ item.name }}</b> via
                        <b>
                          <i>{{ item.email }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    <!-- <span class="subtitle-1 text-left" v-on="on"> -->
                    {{ DateFormatYYMMDD(item.created_at) }}
                    <!-- </span> -->
                  </template>
                  <template v-slot:[`item.billingDate`]="{ item }">
                    <BillingDateConfirmation
                      :key="item._id"
                      :item="payloadBilling(item)"
                      @setDateFn="billingDateFn"
                      @setBillingDateSubmitFn="billingDateSubmitFn"
                    />
                    <!-- <v-btn
                      :key="item._id"
                      icon
                      color="grey lighten-1"
                      @click="billingDateFn(item)"
                    >
                      <font-awesome-icon :icon="['fas', 'calendar-day']" />
                    </v-btn> -->
                  </template>
                  <template v-slot:[`item.approval`]="{ item }">
                    <ApprovalConfirmation
                      :item="item"
                      :key="`itemId-approval ${item._id}`"
                      :approvalFn="approvalFn"
                    />
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <DeleteConfirmation
                      :key="item._id"
                      :item="payloadGen(item)"
                      itemtype="Country Subscriber"
                      @setDelete="deleteFn"
                    />
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-btn
                      icon
                      color="grey lighten-1"
                      @click="RouteFn(item._id, item.email)"
                    >
                      <font-awesome-icon :icon="['fas', 'chevron-right']"/>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
            <!-- Inactive subscribers -->
            <v-tab-item>
              <v-card-text>
                <v-row
                  class="mx-0 mb-0"
                  align="center"
                  justify="center"
                  justify-md="start"
                >
                  <v-col cols="12" md="8" lg="5">
                    <v-text-field
                      v-model="inactiveSearch"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      outlined
                      hide-details
                      @keyup.native="searchFn"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" lg="1">
                    <v-btn
                      color="primary"
                      @click="clearFilterFn"
                      :variant="inactiveSearch ? 'ash-gray' : 'primary'"
                      :disabled="!inactiveSearch"
                    >
                      Reset
                    </v-btn>
                  </v-col>
                </v-row>
                <v-data-table
                  :headers="inactiveHeaders"
                  :items="inactiveItems"
                  :loading="isLoading"
                  :options.sync="inactivePagination"
                  :items-per-page="10"
                  :footer-props="footerProps"
                  @update:items-per-page="getInctiveItemPerPage"
                  :server-items-length="totalOutputsInactive"
                >
                  <template v-slot:[`item.email`]="{ item }">
                    <v-tooltip bottom v-if="item.email">
                      <template v-slot:activator="{ on }">
                        <span class="subtitle-1 text-left" v-on="on">
                          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                        </span>
                      </template>
                      <span>
                        Send an email to
                        <b>{{ item.name }}</b> via
                        <b>
                          <i>{{ item.email }}</i>
                        </b>
                      </span>
                    </v-tooltip>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    <!-- <span class="subtitle-1 text-left" v-on="on"> -->
                    {{ DateFormatYYMMDD(item.created_at) }}
                    <!-- </span> -->
                  </template>
                  <template v-slot:[`item.billingDate`]="{ item }">
                    <!-- <v-btn
                      :key="item._id"
                      icon
                      color="grey lighten-1"
                      @click="billingDateFn(item)"
                    >
                      <font-awesome-icon :icon="['fas', 'calendar-day']" />
                    </v-btn> -->
                    <BillingDateConfirmation
                      :key="item._id"
                      :item="payloadBilling(item)"
                      @setDateFn="billingDateFn"
                      @setBillingDateSubmitFn="billingDateSubmitFn"
                    />
                  </template>
                  <template v-slot:[`item.approval`]="{ item }">
                    <ApprovalConfirmation
                      :item="item"
                      :key="`itemId-approval ${item._id}`"
                      :approvalFn="approvalFn"
                    />
                  </template>
                  <template v-slot:[`item.delete`]="{ item }">
                    <DeleteConfirmation
                      :key="item._id"
                      :item="payloadGen(item)"
                      itemtype="Country Subscriber"
                      @setDelete="deleteFn"
                    />
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-btn
                      icon
                      color="grey lighten-1"
                      @click="RouteFn(item._id, item.email)"
                    >
                      <font-awesome-icon :icon="['fas', 'chevron-right']"/>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </base-material-card>
        <!-- <v-dialog v-model="isDialog" scrollable max-width="500px">
          <v-card>
            <v-card-title class="px-2 px-sm-6">
              <v-row class="pb-3">
                <v-col
                  cols="8"
                  lg="10"
                  class="d-flex flex-column justify-center align-start py-0"
                >
                  <h2 class="headline text-left">Next Billing Date:</h2>
                </v-col>
                <v-col
                  cols="4"
                  lg="2"
                  class="d-flex flex-column justify-center align-end py-0"
                >
                  <v-btn icon color="grey lighten-1" @click="closeDialog">
                    <v-icon class="dialog-close-icon">mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-container class="px-0">
                <v-col cols="12" sm="6" class="py-0">
                  <v-menu
                    ref="nextBillingDateMenu"
                    v-model="nextBillingDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="nextBillingDate"
                        label="Next Billing Date"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="nextBillingDate"
                      no-title
                      @input="nextBillingDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-container>
            </v-card-text>
            <v-card-actions class="px-6">
              <v-row
                class="
                  d-flex
                  flex-column-reverse flex-sm-row
                  align-center
                  justify-space-between
                  mx-0
                "
              >
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex flex-column align-center align-sm-start px-0"
                >
                  <v-btn
                    class="px-8"
                    color="secondary"
                    @click="closeDialog"
                    tile
                    outlined
                    depressed
                    bottom
                    >Cancel</v-btn
                  >
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                  class="d-flex flex-column align-center align-sm-end px-0"
                >
                  <v-btn
                    class="px-8"
                    color="secondary"
                    tile
                    depressed
                    bottom
                    right
                    @click="billingDateSubmitFn"
                    >Submit</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import countrySubscribersApicalls from "../../../services/countrySubscribers.service";

import ApprovalConfirmation from "@/dashboard/Common/ApprovalConfirmation";
import DeleteConfirmation from "@/dashboard/Common/DeleteConfirmation";
import BillingDateConfirmation from "@/dashboard/Common/BillingDateConfirmation";

import isNumber from "../../../mixin/IsNumber";
import DateFormatYYMMDD from "../../../mixin/DateFormatYYMMDD";

export default {
  name: "CountrySub",
  mixins: [isNumber],
  components: {
    ApprovalConfirmation,
    DeleteConfirmation,
    BillingDateConfirmation,
  },
  data() {
    return {
      activeSearch: null,
      inactiveSearch: null,
      isLoading: false,
      adminType: null,
      activeItems: [],
      inactiveItems: [],
      isDialog: false,
      CountrySubTabs: 0,
      totalOutputsActive: 0,
      totalOutputsInactive: 0,
      footerProps: {"items-per-page-options": [10, 20, 30]},
      activePagination: {
        rowsPerPage: 10,
        page: 1,
      },
      inactivePagination: {
        rowsPerPage: 10,
        page: 1,
      },
      activeHeaders: [
        {
          sortable: false,
          text: "Country",
          value: "countryName",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Country Rep.",
          value: "name",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Email",
          value: "email",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Billing Date",
          value: "billingDate",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Joined Date",
          value: "created_at",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Subs. End Date",
          value: "subEndDate",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Approve",
          value: "approval",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Delete",
          value: "delete",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "More",
          value: "status",
          class: "primary--text title",
        },
      ],
      inactiveHeaders: [
        {
          sortable: false,
          text: "Country",
          value: "countryName",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Country Rep.",
          value: "name",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Email",
          value: "email",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Billing Date",
          value: "billingDate",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Joined Date",
          value: "created_at",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Subs. End Date",
          value: "subEndDate",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Approve",
          value: "approval",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "Delete",
          value: "delete",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "More",
          value: "status",
          class: "primary--text title",
        },
      ],
      nextBillingDate: null,
      nextBillingDateMenu: false,
      subscriberId: null,
    };
  },
  computed: {
    // headers() {
    //   // let adminType = JSON.parse(localStorage.getItem("admin")).admin.adminType;
    //   // if (adminType == "master") {
    //   return [
    //     {
    //       sortable: true,
    //       text: "Country",
    //       value: "countryName",
    //       class: "primary--text title",
    //     },
    //     {
    //       sortable: true,
    //       text: "Country Rep.",
    //       value: "name",
    //       class: "primary--text title",
    //     },
    //     {
    //       sortable: true,
    //       text: "Email",
    //       value: "email",
    //       class: "primary--text title",
    //     },
    //     {
    //       sortable: true,
    //       text: "Approve",
    //       value: "approval",
    //       class: "primary--text title",
    //     },
    //     {
    //       sortable: false,
    //       text: "Delete",
    //       value: "delete",
    //       class: "primary--text title",
    //     },
    //     {
    //       sortable: false,
    //       text: "More",
    //       value: "status",
    //       class: "primary--text title",
    //     },
    //   ];
    // },
  },
  mounted() {
    // this.initFn();
  },
  watch: {
    activePagination() {
      this.getActiveSubscribers();
    },
    inactivePagination() {
      this.getInactiveSubscribers();
    },
  },
  methods: {
    DateFormatYYMMDD,
    getActiveItemPerPage(val) {
      this.activePagination.page = 1;
      this.activePagination.rowsPerPage = val;
    },
    getInctiveItemPerPage(val) {
      this.inactivePagination.page = 1;
      this.inactivePagination.rowsPerPage = val;
    },
    getActiveSubscribers() {
      this.isLoading = true;
      // const { page, rowsPerPage } = this.activePagination;
      const {page,rowsPerPage} = this.activePagination;

      countrySubscribersApicalls
        .getAllCountrySubscribers({
          active: 1,
          limit: rowsPerPage, //add rowsPerPage if needed
          page: page,
          name: this.activeSearch,
        })
        .then((res) => {
          this.totalOutputsActive = res.data.data.total;

          this.activeItems = res.data.data.results.map((x) => {
            return {
              countryName: x.countryName ? x.countryName : "N/A",
              name: x.name ? x.name : "N/A",
              email: x.email ? x.email : "N/A",
              approval: x.isApproved,
              subEndDate: x.endDate ? x.endDate.split("T")[0] : "N/A",
              ...x,
            };
          });
          this.isLoading = false;
        });
    },
    getInactiveSubscribers() {
      this.isLoading = true;
      // const { page, rowsPerPage } = this.inactivePagination;
      const {page,rowsPerPage} = this.inactivePagination;
      console.log(page+'-'+rowsPerPage)
      countrySubscribersApicalls
        .getAllCountrySubscribers({
          active: 0,
          limit: rowsPerPage,// rowsPerPage add if needed
          page: page,
          name: this.inactiveSearch,
        })
        .then((res) => {
          this.totalOutputsInactive = res.data.data.total;
          this.inactiveItems = res.data.data.results.map((x) => {
              return {
                countryName: x.countryName ? x.countryName : "N/A",
                name: x.name ? x.name : "N/A",
                email: x.email ? x.email : "N/A",
                approval: x.isApproved,
                subEndDate: x.endDate ? x.endDate.split("T")[0] : "N/A",
                ...x,
              };
          });
          this.isLoading = false;
        });
    },

    approvalFn(val) {
      this.isLoading = true;
      let payload = {
        approve: !val.isApproved,
      };
      countrySubscribersApicalls.setApproval(val._id, payload).then((res) => {
        if (res.data.status == "OK") {
          this.getActiveSubscribers();
          this.getInactiveSubscribers();
          let payload2 = val.isApproved
            ? {
              snackBarText: `This country's '${val.countryName}' approval has been revoked`,
              snackBarColor: "secondary",
            }
            : {
              snackBarText: `The country '${val.countryName}' has been approved`,
              snackBarColor: "success",
            };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);

          // countrySubscribersApicalls.getAllCountrySubscribers().then((res) => {
          //   this.activeItems = res.data.data.results;
          //   let payload2 = val.isApproved
          //     ? {
          //         snackBarText: `This country's '${val.countryName}' approval has been revoked`,
          //         snackBarColor: "secondary",
          //       }
          //     : {
          //         snackBarText: `The country '${val.countryName}' has been approved`,
          //         snackBarColor: "success",
          //       };
          //   this.$store.dispatch("notify/setSnackbarMessage", payload2);
          // });
          // countrySubscribersApicalls.getAllCountrySubscribers().then((res) => {
          //   this.inactiveItems = res.data.data.results;
          //   let payload2 = val.isApproved
          //     ? {
          //         snackBarText: `This country's '${val.countryName}' approval has been revoked`,
          //         snackBarColor: "secondary",
          //       }
          //     : {
          //         snackBarText: `The country '${val.countryName}' has been approved`,
          //         snackBarColor: "success",
          //       };
          //   this.$store.dispatch("notify/setSnackbarMessage", payload2);
          // });
        } else {
          let payload2 = {
            snackBarText: `This country '${val.countryName}' is owned by someone else`,
            snackBarColor: "error",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
        }
        this.isLoading = false;
      });
    },
    deleteFn(val) {
      console.log("ddd", val._id);
      this.isLoading = true;
      countrySubscribersApicalls.setDelete(val._id).then((res) => {
        if (res.data.msg == "OK") {
          console.log(res.data);
          let payload2 = {
            snackBarText: `The country subscriber ${val.name}'s account has been deleted successfully`,
            snackBarColor: "secondary",
          };
          this.deleteDialog = false;
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
          countrySubscribersApicalls.getAllCountrySubscribers().then((res) => {
            this.activeItems = res.data.data.results;
          });
          countrySubscribersApicalls.getAllCountrySubscribers().then((res) => {
            this.inactiveItems = res.data.data.results;
          });
        }
        this.isLoading = false;
      });
    },
    payloadGen(country) {
      return {
        name: country.name,
        type: "Country Subscriber",
        _id: country._id,
      };
    },
    payloadBilling(item) {
      const endDate = new Date(item.created_at.split("T")[0]);
      this.nextBillingDate = `${endDate.getFullYear() + 1}-${
        endDate.getMonth() + 1
      }-${endDate.getDate()}`;
      if(!item.endDate){
        console.log(item)
      }
      return {
        nextBillingDate: item.endDate
          ? item.endDate.split("T")[0]
          : `${endDate.getFullYear() + 1}-${
            endDate.getMonth() + 1
          }-${endDate.getDate()}`,
        _id: item._id,
      };
    },
    RouteFn(id, email) {
      this.$store.dispatch("setUserEmail", email);
      localStorage.setItem("setUserEmail", email);
      this.$router.push(`/country-subscribers-view/${id}`);
    },
    searchFn() {
      if (
        (this.activeSearch && this.activeSearch.length > 0) ||
        (this.inactiveSearch && this.inactiveSearch.length>0)
      ) {
        this.activePagination.page = 1;
        this.inactivePagination.page = 1;
        this.getActiveSubscribers();
        this.getInactiveSubscribers();
      }else{
        this.clearFilterFn();
      }
    },
    clearFilterFn() {
      this.activeSearch = null;
      this.inactiveSearch = null;

      this.activePagination.page = 1;
      this.inactivePagination.page = 1;

      this.getActiveSubscribers();
      this.getInactiveSubscribers();
    },
    billingDateFn(item) {
      this.isDialog = true;
      this.subscriberId = item._id;

      // if (item.endDate) {
      //   this.nextBillingDate = item.endDate.split("T")[0];
      // } else {
      //   const endDate = new Date(item.created_at.split("T")[0]);
      //   this.nextBillingDate = `${endDate.getFullYear() + 1}-${
      //     endDate.getMonth() + 1
      //   }-${endDate.getDate()}`;
      // }
      // const createdDate = new Date(item.created_at.split("T")[0]);

      // let addZero = (el) =>
      //   (el.toString().length == 1 ? "0" : "") + el.toString();

      // const prevDate = `${createdDate.getFullYear() + 1}-${addZero(
      //   createdDate.getMonth() + 1
      // )}-${createdDate.getDate()}`;

      // const newDate = item.endDate.split("T")[0];

      // console.log("prev", prevDate);
      // console.log("new", newDate);

      // if (newDate == prevDate) {
      //   this.nextBillingDate = prevDate;
      //   console.log("test");
      // } else {
      //   console.log("test1");
      //   this.nextBillingDate = item.endDate.split("T")[0];
      // }
      // const endDate = new Date(item.created_at.split("T")[0]);
      // console.log(endDate.toISOString());
      // this.nextBillingDate = `${endDate.getFullYear() + 1}-${
      //   endDate.getMonth() + 1
      // }-${endDate.getDate()}`;
    },
    closeDialog() {
      this.isDialog = false;
    },
    billingDateSubmitFn(val) {
      this.isDialog = false;
      this.isLoading = true;

      let payload = {
        date: val.nextBillingDate,
      };
      countrySubscribersApicalls
        .setBillingDate(this.subscriberId, payload)
        .then(() => {
          this.getActiveSubscribers();
          this.getInactiveSubscribers();
          this.isLoading = false;

          let payload2 = {
            snackBarText: `Billing Date has been updated`,
            snackBarColor: "success",
          };
          this.$store.dispatch("notify/setSnackbarMessage", payload2);
        });
    },
  },
};
</script>
<style scoped>
.upgrade-btn {
  min-width: 140px !important;
}

.v-dialog .v-card .v-card__title .v-icon.dialog-close-icon {
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 32px;
  height: 32px;
  font-size: 24px;
}
</style>
